import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import {
  documentTypeDisplay,
  DocumentType,
  ContractStatus,
  ContractDocument,
  Validity,
  BankAccountSource,
  BankAccount,
} from "../../../data/models/ContractTypes";
import {
  ContractId,
  CorrelationId,
  DocumentId,
} from "../../../data/models/CommonTypes";
import {
  LookupContractResponse,
  dataContracts,
} from "../../../data/dataContracts";
import { Page } from "../../Page";
import { useSetAtom } from "jotai";
import { useQueryClient, useSuspenseQueries } from "@tanstack/react-query";
import { SupplementWithId, dataDocuments } from "../../../data/dataDocuments";
import { T } from "../../../components/translation/T";
import { getIntlDate } from "../../../components/utils";
import { Link } from "../../../components/links/Link";
import { API } from "../../../network/API";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "../../../components/form/Form";
import { Select } from "../../../components/form/Select";
import { WarningBox } from "../../../components/boxes/WarningBox";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Status } from "../../../data/types";
import { hasRealErrors } from "../../../components/form/FormHelpers";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { TextArea } from "../../../components/form/TextArea";
import { DangerBox } from "../../../components/boxes/DangerBox";
import { GenericError } from "../../../components/boxes/GenericError";
import { FileRow } from "../../../components/fileUpload/FileRow";
import { Beacon } from "../../../components/beacon/Beacon";
import { Alternative } from "../../../components/interactions/InputTypes";
import { useParams } from "react-router-dom";
import { Retry } from "../../../components/retry/Retry";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { contractAuthState } from "../../../state/contractAuthState";
import { SfLinkWithUrl } from "../../../components/links/SfLink";
import { ConfirmDoc } from "../Review/Timeline/ConfirmDocs/ConfirmDoc";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import { Country } from "../../../i18n";
import { dataBank } from "../../../data/dataBank";
import styles from "./SupplementsPage.module.scss";

export type ContractIdParams = {
  correlationId: CorrelationId;
};

export const SUPPLEMENTS_ROUTE = "/salesforce/:correlationId/documents";

const ALLOWED_SUPPLEMENTS = [
  DocumentType.RECORD_OF_NOMINEE_SHAREHOLDERS,
  DocumentType.CONTROL_STRUCTURE,
  DocumentType.SALES_CUSTOM_REQUEST,
  DocumentType.ECOM_APPLICATION_SCREENSHOT,
  DocumentType.FINANCIAL_STATEMENT,
  DocumentType.LETTER_OF_SHAREHOLDER_CUSTODIAN,
  DocumentType.POWER_OF_ATTORNEY,
  DocumentType.PROOF_OF_ACCREDITATION,
  DocumentType.ID_DOCUMENT,
  DocumentType.TAXI_LICENSE,
  DocumentType.BANK_STATEMENT,
];

function getStatus(doc: ContractDocument) {
  if (!!doc.confirmed) {
    return (
      <>
        <Beacon mini className={styles.aligned} validity={Validity.VALID} />
        <T>Confirmed</T> {getIntlDate(doc.confirmed)}
      </>
    );
  }

  if (!doc.uploaded) {
    return (
      <>
        <Beacon mini className={styles.aligned} validity={Validity.MISSING} />
        <T>Has not yet been uploaded</T>
      </>
    );
  }

  return (
    <>
      <Beacon mini className={styles.aligned} validity={Validity.PARTIAL} />
      <T>Has not yet been confirmed</T>
    </>
  );
}

export function getAlternatives(
  docs: ContractDocument[],
  t: any,
  addedDocTypes: DocumentType[] = []
): Alternative<DocumentType>[] {
  const validDocTypes = Object.keys(DocumentType)
    .filter((key) => ALLOWED_SUPPLEMENTS.includes(key as DocumentType))
    .filter((key) => {
      if (docs.find((doc) => doc.documentType === key && !doc.uploaded)) {
        return false;
      }

      if (docs.find((doc) => doc.documentType === key && !doc.confirmed)) {
        return false;
      }

      if (addedDocTypes.find((documentType) => documentType === key)) {
        return false;
      }

      return true;
    });

  const opts = validDocTypes.map((key) => ({
    value: key as DocumentType,
    text: t(documentTypeDisplay(key as DocumentType)),
    disabled: false,
  }));

  opts.unshift({
    value: "" as DocumentType,
    text: t("Select type of document"),
    disabled: true,
  });

  return opts;
}

function getFileName(doc: ContractDocument) {
  if (!doc.uploaded) {
    return <T>Waiting for customer to upload</T>;
  }

  if (doc.documentType === DocumentType.BANK_STATEMENT) {
    return <T>Uploaded bank statement</T>;
  }

  return doc.fileName || <T>Missing file name</T>;
}

export const SupplementsPage: React.FunctionComponent = () => {
  const { correlationId } = useParams<ContractIdParams>();

  const countryPrefix = Object.values(Country).find((country) => {
    return correlationId?.startsWith(`${country}-`);
  });

  if (!correlationId) {
    return null;
  }

  if (countryPrefix) {
    return (
      <SupplementsPageContractId
        contractId={correlationId as unknown as ContractId}
      />
    );
  }

  return <SupplementsPageCorrelationId />;
};

export const SupplementsPageCorrelationId: React.FunctionComponent = () => {
  const { correlationId } = useParams<ContractIdParams>();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [data, setData] = useState<LookupContractResponse>();
  const setAuthState = useSetAtom(contractAuthState);

  const load = useCallback(() => {
    dataContracts
      .companyLookup(correlationId || ("" as CorrelationId))
      .then((data) => {
        setData(data);
        setStatus(Status.SUCCESS);
      })
      .catch(() => setStatus(Status.ERROR));
  }, [correlationId]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      load();
    }, 500);
  }, [load]);

  useEffect(() => {
    setAuthState((prev) => ({ ...prev, referrer: document.referrer }));
    load();
  }, [load, setAuthState]);

  if (!data) {
    return null;
  }

  if (!data.contractId) {
    return (
      <ErrorBox relative>
        <T>
          We can't show the documents for this contract. Contract is in state:
        </T>{" "}
        <b>{data.status}</b>
      </ErrorBox>
    );
  }

  return (
    <Retry retry={retry} status={status}>
      <SupplementsPageContractId
        contractId={data.contractId ?? ("" as ContractId)}
      />
    </Retry>
  );
};

function bankStatementToDoc(bankAccount: BankAccount): ContractDocument {
  const id = (
    bankAccount.statementUploaded
      ? new Date(bankAccount.statementUploaded)
      : new Date()
  ).getTime() as DocumentId;

  return {
    documentId: id,
    documentType: DocumentType.BANK_STATEMENT,
    fileAvailable: !!bankAccount.statementUploaded,
    uploaded: bankAccount.statementUploaded,
    confirmed: bankAccount.signedOff,
  };
}

export const SupplementsPageContractId: React.FunctionComponent<{
  contractId: ContractId;
}> = ({ contractId }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [doc, setDoc] = useState<DocumentType>("" as DocumentType);
  const [docs, setDocs] = useState<SupplementWithId[]>([]);
  const [message, setMessage] = useState<string>();
  const id = useRef<number>(1);
  const queryClient = useQueryClient();
  const [addedDocTypes, setAddedDocTypes] = useState<DocumentType[]>([]);
  const [confirmDoc, setConfirmDoc] = useState<ContractDocument>();

  const [{ data: documents }, { data: contract }, { data: bankAccount }] =
    useSuspenseQueries({
      queries: [
        dataDocuments.fetchDocuments(contractId),
        dataContracts.fetchContract(contractId),
        dataBank.fetchBank(contractId),
      ],
    });

  const disabled = contract.status !== ContractStatus.RISK_CONFIRMATION;

  const onConfirm = useCallback((doc: ContractDocument) => {
    setConfirmDoc(doc);
  }, []);

  const onClose = useCallback(() => setConfirmDoc(undefined), []);

  const onAdd = useCallback(() => {
    if (!doc) {
      return;
    }

    setMessage("");
    setDoc("" as DocumentType);
    const item: SupplementWithId = {
      documentType: doc,
      documentDescription: message,
      documentId: id.current as DocumentId,
    };
    id.current = id.current + 1;
    setDocs((prev) => [...prev, item]);
    setAddedDocTypes((prev) => [...prev, doc]);
  }, [doc, message]);

  const onRemove = useCallback((id: DocumentId) => {
    setDocs((prev) => prev.filter((doc) => doc.documentId !== id));
  }, []);

  const onSave = useCallback(() => {
    setStatus(Status.PENDING);
    dataDocuments
      .saveSupplements(contractId, docs)
      .then(() => {
        setStatus(Status.SUCCESS);
        setDocs([]);
        setAddedDocTypes([]);
        queryClient.invalidateQueries({
          queryKey: dataDocuments.getDocumentsKey(contractId),
        });
        queryClient.invalidateQueries({
          queryKey: dataContracts.getContractKey(contractId),
          refetchType: "all",
        });
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 1000);
      })
      .catch((err) => {
        setStatus(Status.ERROR);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [docs, contractId, queryClient]);

  const onChange = useCallback((value: DocumentType) => setDoc(value), []);

  const onMessageChange = useCallback((value: string) => setMessage(value), []);

  const sortedDocs = useMemo(() => {
    const docs =
      bankAccount?.source === BankAccountSource.BANK_STATEMENT
        ? [...documents, bankStatementToDoc(bankAccount)]
        : documents;

    return docs
      .filter((doc) => ALLOWED_SUPPLEMENTS.includes(doc.documentType))
      .sort((a, b) => {
        if (!a.uploaded) {
          return -1;
        }

        if (!b.uploaded) {
          return -1;
        }

        return new Date(b.uploaded).getTime() - new Date(a.uploaded).getTime();
      });
  }, [documents, bankAccount]);

  const alternatives = useMemo(
    () => getAlternatives(documents, t, addedDocTypes),
    [t, addedDocTypes, documents]
  );

  const getDocLink = useCallback(
    (doc: ContractDocument) => {
      if (!doc.uploaded) {
        return null;
      }

      let link;
      if (doc.documentType === DocumentType.BANK_STATEMENT) {
        link = `${API.getUrl(`/api/sales/${contractId}/bank/document`)}?q=${
          doc.uploaded
        }`;
      } else {
        link = `${API.getUrl(
          `/api/sales/${contractId}/documents/${doc.documentId}`
        )}?q=${doc.uploaded}`;
      }

      return (
        <Link className="small" external link={link}>
          {getFileName(doc)}
        </Link>
      );
    },
    [contractId]
  );

  return (
    <Page background stripped title="Supplements">
      <section>
        <article>
          <div className={styles.page}>
            <h1>{contract.contractData.companyName}</h1>
          </div>

          <NewOverlay open={!!confirmDoc} onClose={onClose} width={1000}>
            <ConfirmDoc
              onClose={onClose}
              contractId={contractId}
              document={
                documents.find(
                  (v) => v.documentId === confirmDoc?.documentId
                ) || null
              }
            />
          </NewOverlay>

          {contract.status === ContractStatus.RISK_CONFIRMATION ? null : (
            <div className={styles.warning}>
              <DangerBox>
                <T>
                  Supplements may be requested when the application is
                  scrutinized by Worldline.
                </T>
                <div className="m-top-10">
                  <Trans
                    status={contract.status}
                    values={{ status: contract.status }}
                    i18nKey="Since this application is <strong>not in the correct state</strong> ({{status}}), supplement reachout is not available."
                  />
                </div>
              </DangerBox>
            </div>
          )}

          <div className="m-bottom-20">
            <SfLinkWithUrl link={contract.salesforceUrl}>
              <T>Back to Salesforce</T>
            </SfLinkWithUrl>
          </div>

          <div className={styles.docs}>
            <div className={styles.timeline}>
              <h4>
                <T>Application documents</T>
              </h4>
              <div className={styles.top} />
              <ul>
                {sortedDocs.map((doc) => {
                  return (
                    <li key={doc.documentId} className={styles.doc}>
                      <svg className={styles.dataIndicator} viewBox="0 0 25 10">
                        <circle cx="0" cy="5" r="3" />
                        <line x1="0" x2="25" y1="5" y2="5" />
                        <circle cx="25" cy="5" r="2" />
                      </svg>
                      <div className="mini">
                        <i>
                          {!!doc.uploaded ? (
                            <>
                              <T>Uploaded</T> {getIntlDate(doc.uploaded)}
                            </>
                          ) : (
                            <div className={cx("mini", styles.tag)}>
                              <div className={styles.bg} />
                              <div>
                                <T>Requested</T> {getIntlDate(doc.created)}
                              </div>
                            </div>
                          )}
                        </i>
                      </div>

                      <b className={styles.name}>
                        <T>{documentTypeDisplay(doc.documentType)}</T>
                      </b>
                      <div className="small m-bottom-10">
                        {doc.documentDescription ? (
                          <i>
                            <T>{doc.documentDescription}</T>
                          </i>
                        ) : null}
                      </div>

                      <i className={cx("small", [styles.confirmed])}>
                        {getStatus(doc)}{" "}
                        {!!doc.uploaded &&
                        !doc.confirmed &&
                        doc.documentType !==
                          DocumentType.BANK_STATEMENT /** we don't allow for bank statements to be confirmed here */ ? (
                          <>
                            <Button
                              className={cx("mini", styles.confirm)}
                              onClick={onConfirm}
                              data={doc}
                            >
                              <T>Confirm</T>
                            </Button>
                          </>
                        ) : null}
                      </i>

                      <div className="small">{getDocLink(doc)}</div>
                    </li>
                  );
                })}
              </ul>
              <div className={styles.bottom} />
            </div>

            <div className={styles.form}>
              <div>
                <h4>
                  <T>Request supplements</T>
                </h4>
                <p>
                  <Trans>
                    Once saved, these supplements will be{" "}
                    <b>added to the timeline</b> and we will notify the merchant
                    in order for him/her to upload them.
                  </Trans>
                </p>
                <Form
                  className="m-top-40"
                  onSubmit={(_, form) => {
                    const realErrors = hasRealErrors(form);

                    if (!form.isValid) {
                      return;
                    }

                    if (!realErrors) {
                      onAdd();
                    }
                  }}
                >
                  <Select
                    name="select-doc"
                    label="Supplements"
                    alternatives={alternatives}
                    value={doc}
                    onChange={onChange}
                    validators={[
                      new RequiredValidator("Select a document type"),
                    ]}
                    disabled={disabled || status !== Status.DEFAULT}
                  />

                  <AnimateHeight
                    name={
                      doc === DocumentType.SALES_CUSTOM_REQUEST ? "up" : "down"
                    }
                  >
                    {doc === DocumentType.SALES_CUSTOM_REQUEST ? (
                      <>
                        <TextArea
                          name="custom-request"
                          className={styles.textarea}
                          value={message}
                          onChange={onMessageChange}
                          label="Document description"
                          disabled={disabled || status !== Status.DEFAULT}
                          validators={[
                            new RequiredValidator(
                              "Please provide a text that describes the document"
                            ),
                          ]}
                        />

                        <WarningBox relative>
                          <T>
                            Observe that the message must be in the language of
                            the merchant since we are unable to translate
                            arbitrary texts
                          </T>
                        </WarningBox>
                      </>
                    ) : (
                      <div />
                    )}
                  </AnimateHeight>

                  <Button
                    block
                    type="submit"
                    className="m-top-20"
                    status={
                      disabled ||
                      alternatives.length === 1 ||
                      status !== Status.DEFAULT
                        ? Status.DISABLED
                        : Status.DEFAULT
                    }
                  >
                    <T>Add document</T>
                  </Button>

                  {!!docs.length ? (
                    <div className="m-top-40">
                      <AnimateHeight name={docs.length.toString()}>
                        {docs.length === 0 ? (
                          <div />
                        ) : (
                          <>
                            <b>
                              <T>Added documents</T>
                            </b>
                            <ul className="m-top-10">
                              {docs.map((item) => (
                                <li key={item.documentId}>
                                  <FileRow
                                    document={item}
                                    onRemove={onRemove}
                                    allowRemove
                                    status={
                                      status !== Status.DEFAULT
                                        ? Status.DISABLED
                                        : status
                                    }
                                  />
                                </li>
                              ))}
                            </ul>

                            <div className="m-top-40">
                              <AnimateHeight name={status}>
                                {status === Status.ERROR ? (
                                  <GenericError />
                                ) : (
                                  <div />
                                )}
                              </AnimateHeight>

                              <Button
                                className="m-top-10"
                                ghost
                                block
                                onClick={onSave}
                                status={status}
                              >
                                <T>Save documents</T>
                              </Button>
                            </div>
                          </>
                        )}
                      </AnimateHeight>
                    </div>
                  ) : null}
                </Form>
              </div>
            </div>
          </div>
          <hr />
        </article>
      </section>
    </Page>
  );
};
