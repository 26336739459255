import React, { ReactNode } from "react";
import cx from "classnames";
import { T } from "../translation/T";
import { Logo } from "../images/Logo";
import { useContract } from "../../hooks/useContract";
import "./Link.scss";

interface Props {
  children: ReactNode;
  className?: string;
  logo?: boolean;
}

interface InnerProps extends Props {
  link: string;
}

export const SfLink: React.FunctionComponent<Props> = ({
  children,
  className,
  logo,
}) => {
  const { salesforceUrl } = useContract();

  if (!salesforceUrl) {
    if (logo) {
      return (
        <div
          style={{
            flex: "0 0 auto",
          }}
        >
          <Logo />
        </div>
      );
    }

    return (
      <>
        🤯 <T>Missing salesforce link!!</T>
      </>
    );
  }

  return (
    <SfLinkWithUrl className={className} link={salesforceUrl}>
      {children}
    </SfLinkWithUrl>
  );
};

export const SfLinkWithUrl: React.FunctionComponent<InnerProps> = ({
  children,
  className,
  link,
}) => {
  return (
    <a
      className={cx("link", "external-link", "postfix", className)}
      href={link}
    >
      {children}
    </a>
  );
};
